let response_json = require('./responses.json')

export default function getCenters(region, municipality) {
    let centers = []
    console.log("For "+ region + municipality)
    response_json.forEach((e) => {
        if(e["municipality"] == municipality && e["region"] == region) {
            centers.push({value: e.name, label: e.name, object: e})
        }
    })
    console.log(centers)
    return centers
}