
import LocalizedStrings from 'react-localization';

let localizedStrings = new LocalizedStrings({
    en:{
      overall:"Overall Impression",
      availability: "Availability",
      emotional_support: "Emotional Support",
      participation: "Participation and Involvement",
      respect: "Respect and Personal Treatment",
      continuity: "Continuity and Coordination",
      information: "Information and Knowledge",
      average: "Average Score",
      footer: "A page by Felix Örnebjär Dellner - please use it however you see fit. Data from Nationell Patientenkät 2023.",
      ai_tba: "Soon you will be able to chat with an AI assistant here to help you compare centers.",
      region: "Region",
      municipality: "Municipality",
      caregiver: "Caregiver",
      none_selected: "Select at least one center to view data."
    },
    sv: {
      overall:"Helhetsintryck",
      availability: "Tillgänglighet",
      emotional_support: "Emotionellt Stöd",
      participation: "Delaktighet och Involvering",
      respect: "Respekt och Bemötande",
      continuity: "Kontinuitet och Koordinering",
      information: "Information och Kunskap",
      average: "Genomsnittspoäng",
      footer: "En hemsida skapad med kärlek av Felix Örnebjär Dellner. Använd den hur du vill! Data från Nationell Patientenkät 2023.",
      ai_tba: "Snart kommer du kunna chatta med en AI-Assistent för att jämföra vårdcentraler här.",
      region: "Region",
      municipality: "Kommun",
      caregiver: "Vårdgivare",
      none_selected: "Välj åtminstone en vårdgivare för att se en översikt här."
    },
    ar: {
      overall: "الانطباع العام",
      availability: "بەردەست بوون",
      emotional_support: "پشتگیری سۆزداری",
      participation: "بەشداریکردنی نەخۆش",
      respect: "الاحترام والعناية الشخصية",
      continuity: "الاستمرارية والتنسيق",
      information: "زانیاری و مەعریفە",
      average: "تێکڕای نمرە",
      footer: "لاپەڕەیەک لەلایەن Felix Örnebjär Dellner  - تکایە بە هەر شێوەیەک کە حەزت لێیە بەکاری بهێنە. زانیارییەکانی ڕاپرسی نیشتمانی نەخۆشەکانی ساڵی ٢٠٢٣.",
      ai_tba:"بەم زووانە دەتوانیت لێرە لەگەڵ یاریدەدەرێکی AI چات بکەیت بۆ ئەوەی یارمەتیت بدات لە بەراوردکردنی سەنتەرەکان.",
      region: "المنطقة",
      municipality: "بلدية",
      caregiver: "مقدم الرعاية",
      none_selected: "تکایە لانیکەم یەک سەنتەری چاوەدێری سەرەتایی هەڵبژێرە بۆ بینینی داتاکان لێرە."
    },
    fi: {
      overall: "Yleisarvio",
      availability: "Saatavuus",
      emotional_support: "Emotionaalinen tuki",
      participation: "Osallistuminen ja vuorovaikutus",
      respect: "Kunnioitus ja henkilökohtainen kohtelu",
      continuity: "Jatkuvuus ja koordinointi",
      information: "Tiedot ja osaaminen",
      average: "Keskimääräinen pistemäärä",
      footer: "Sivun kirjoittaja: Felix Örnebjär Dellner - käytä sitä miten haluat. Tiedot: Valtakunnallinen potilastutkimus 2023.",
      ai_tba: "Pian voit keskustella täällä tekoälyavustajan kanssa, joka auttaa vertailemaan keskuksia.",
      region: "Alue",
      municipality: "Kunta",
      caregiver: "Omaishoitaja",
      none_selected: "Valitse vähintään yksi keskus nähdäksesi tiedot."
    },
    ku: {
      overall: "تێگەیشتنی گشتی",
      availability: "ئامادەبوون",
      emotional_support: "پشتیوانی حیسابی",
      participation: "بەشداربوون و بەشداری",
      respect: "ڕێزگرتن و چارەسەرکردنی کەسایەتی",
      continuity: "بەردەوامی و هاوکاری",
      information: "زانیاری و زانست",
      average: "نمرەی ناوەڕاست",
      footer: "لاپەڕەیەک لەلایەن فیلیکس ئۆرنبەیار دەلنەر - تکایە بە هەریەک شێوەیەک کە دەتەوێت بەکاربێنە. داتاکان لە پەیڤەنامەی نەشتەرگەری نیشتمانی 2023.",
      ai_tba: "بە زووی دەتوانیت لە ئەمێ ڕەنگا کە ڕێکەوتنە ئای-ئی کە لێی یارمەتی دابنێن، ڕاگەیەندراویەکانت بەردەست بکەیت.",
      region: "ھەرێم",
      municipality: "شار",
      caregiver: "چاکسازی",
      none_selected: "تکایە بەلایەنە، یەک لە چاکسازیەکان بکرێ، بۆ بینینی زانیاری."
    },
    es: {
      overall: "Impresión General",
      availability: "Disponibilidad",
      emotional_support: "Apoyo Emocional",
      participation: "Participación e Involucración",
      respect: "Respeto y Trato Personal",
      continuity: "Continuidad y Coordinación",
      information: "Información y Conocimiento",
      average: "Puntuación Media",
      footer: "Una página hecho de Felix Örnebjär Dellner - úsala como mejor te parezca. Datos de la Encuesta Nacional de Pacientes 2023. Felix Örnebjär Dellner - úsala como mejor te parezca. Datos de la Encuesta Nacional de Pacientes 2023.",
      ai_tba: "Pronto podrás chatear con un asistente de IA aquí para ayudarte a comparar centros.",
      region: "Región",
      municipality: "Municipio",
      caregiver: "Proveedor de Cuidados",
      none_selected: "Selecciona al menos un centro de cuidados para ver los datos."
    }
   });
export default localizedStrings