import { Component } from 'react';
import localizedStrings from './locale';

import { RadarChart, PolarAngleAxis, PolarGrid, PolarRadiusAxis, Radar, Legend } from 'recharts';
import { BarChart, ResponsiveContainer, CartesianGrid, XAxis, YAxis,Tooltip, Bar } from 'recharts';
let colors = [
    "#4D869C",
    "#7AB2B2",
    "#9aadad",
    "#74b8b8",
    "#74b8a1",
    "#42806b",
    "#244a3d"
]
class RadarClass extends Component {
    constructor(props) {
        super(props)
        this.state = [{data: []}]
    }
    componentDidUpdate(prevProps) {
        console.log("hello")
        console.log(this.props.data)
        if(prevProps == this.props) {
            return;
        }
        let availability = {"subject": localizedStrings.availability}
        let overall_impression = {"subject": localizedStrings.overall}
        let emotional_support = {"subject": localizedStrings.emotional_support} 
        let participation = {"subject": localizedStrings.participation }
        let respect = {"subject": localizedStrings.respect}
        let continuity = {"subject": localizedStrings.continuity}
        let information_and_knowledge = {"subject": localizedStrings.information}
        let average = {"subject": localizedStrings.average}
        if(this.props.data == null) {
            console.log("received null")
            return;
        }
        this.props.data.forEach(element => {
            
            let response_object = element.object
            availability[response_object.name] = response_object.availability
            overall_impression[response_object.name] = response_object.overall_impression
            emotional_support[response_object.name] = response_object.emotional_support
            participation[response_object.name] = response_object.participation
            respect[response_object.name] = response_object.respect
            continuity[response_object.name] = response_object.continuity
            information_and_knowledge[response_object.name] = response_object.information_and_knowledge
            average[response_object.name] = parseFloat(response_object.average).toFixed(2)

        });
        this.setState({
            data: [overall_impression, availability, emotional_support, participation, respect, continuity, information_and_knowledge],
            average: [average]
        })
    }
    
    render() {
        if (!Array.isArray(this.props.data) || this.props.data.length === 0 || !Array.isArray(this.state.data) || this.state.data.length === 0) {
            return (
                <p className='colored-text'>{localizedStrings.none_selected}</p>
            );
        }
        return (
            <div>
                <ResponsiveContainer className={"chartContainer"} width="100%" height={400}>
                    <RadarChart outerRadius={150} width={930} height={400} data={this.state.data} className='radar'>
                        <PolarGrid />
                        <PolarAngleAxis dataKey="subject" />
                        <PolarRadiusAxis angle={30} domain={[0, 100]} />
                        {this.props.data.map((element, key) => {
                            console.log(key)
                            return(<Radar key={key} name={element.object.name} dataKey={element.object.name} stroke={colors[key]} fill={colors[key]} fillOpacity={0.6} />)
                        })}
                        
                        <Legend />
                    </RadarChart>
                    <p className='colored-text average-text'>{localizedStrings.average}</p>
                    <BarChart width={130} height={50} data={this.state.average} barGap={1} className='bar'>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        {this.props.data.map((element, key) => {
                            console.log(key)
                            return(<Bar key={key} name={element.object.name.substring(0,15)} dataKey={element.object.name}  stroke={colors[key]} fill={colors[key]} fillOpacity={0.6} />)
                        })}
                    </BarChart>
                </ResponsiveContainer>
                
            </div>
        )
    }

}
export default RadarClass
/*

*/