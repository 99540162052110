export const regions = [
    {
        value: "Stockholm", label: "Stockholm", municipalities:
            [
                { value: "Botkyrka", label: "Botkyrka" },
                { value: "Danderyd", label: "Danderyd" },
                { value: "Ekerö", label: "Ekerö" },
                { value: "Haninge", label: "Haninge" },
                { value: "Huddinge", label: "Huddinge" },
                { value: "Järfälla", label: "Järfälla" },
                { value: "Lidingö", label: "Lidingö" },
                { value: "Nacka", label: "Nacka" },
                { value: "Norrtälje", label: "Norrtälje" },
                { value: "Nykvarn", label: "Nykvarn" },
                { value: "Nynäshamn", label: "Nynäshamn" },
                { value: "Salem", label: "Salem" },
                { value: "Sigtuna", label: "Sigtuna" },
                { value: "Sollentuna", label: "Sollentuna" },
                { value: "Solna", label: "Solna" },
                { value: "Stockholm", label: "Stockholm" },
                { value: "Sundbyberg", label: "Sundbyberg" },
                { value: "Södertälje", label: "Södertälje" },
                { value: "Tyresö", label: "Tyresö" },
                { value: "Täby", label: "Täby" },
                { value: "Upplands-Bro", label: "Upplands-Bro" },
                { value: "Upplands Väsby", label: "Upplands Väsby" },
                { value: "Vallentuna", label: "Vallentuna" },
                { value: "Vaxholm", label: "Vaxholm" },
                { value: "Värmdö", label: "Värmdö" },
                { value: "Österåker", label: "Österåker" }
            ]
    },
    {
        value: "Västra Götaland", label: "Västra Götaland", municipalities: [
            { value: "Ale", label: "Ale" },
            { value: "Alingsås", label: "Alingsås" },
            { value: "Bengtsfors", label: "Bengtsfors" },
            { value: "Bollebygd", label: "Bollebygd" },
            { value: "Borås", label: "Borås" },
            { value: "Dals-Ed", label: "Dals-Ed" },
            { value: "Essunga", label: "Essunga" },
            { value: "Falköping", label: "Falköping" },
            { value: "Färgelanda", label: "Färgelanda" },
            { value: "Grästorp", label: "Grästorp" },
            { value: "Gullspång", label: "Gullspång" },
            { value: "Göteborg", label: "Göteborg" },
            { value: "Götene", label: "Götene" },
            { value: "Herrljunga", label: "Herrljunga" },
            { value: "Hjo", label: "Hjo" },
            { value: "Härryda", label: "Härryda" },
            { value: "Kungälv", label: "Kungälv" },
            { value: "Lerum", label: "Lerum" },
            { value: "Lidköping", label: "Lidköping" },
            { value: "Lilla Edet", label: "Lilla Edet" },
            { value: "Lysekil", label: "Lysekil" },
            { value: "Mariestad", label: "Mariestad" },
            { value: "Mark", label: "Mark" },
            { value: "Mellerud", label: "Mellerud" },
            { value: "Munkedal", label: "Munkedal" },
            { value: "Mölndal", label: "Mölndal" },
            { value: "Orust", label: "Orust" },
            { value: "Partille", label: "Partille" },
            { value: "Skara", label: "Skara" },
            { value: "Skövde", label: "Skövde" },
            { value: "Sotenäs", label: "Sotenäs" },
            { value: "Stenungsund", label: "Stenungsund" },
            { value: "Strömstad", label: "Strömstad" },
            { value: "Svenljunga", label: "Svenljunga" },
            { value: "Tanum", label: "Tanum" },
            { value: "Tibro", label: "Tibro" },
            { value: "Tidaholm", label: "Tidaholm" },
            { value: "Tjörn", label: "Tjörn" },
            { value: "Tranemo", label: "Tranemo" },
            { value: "Trollhättan", label: "Trollhättan" },
            { value: "Töreboda", label: "Töreboda" },
            { value: "Uddevalla", label: "Uddevalla" },
            { value: "Ulricehamn", label: "Ulricehamn" },
            { value: "Vara", label: "Vara" },
            { value: "Vårgårda", label: "Vårgårda" },
            { value: "Vänersborg", label: "Vänersborg" },
            { value: "Åmål", label: "Åmål" },
            { value: "Öckerö", label: "Öckerö" },
        ]
    },
    {
        value: "Skåne", label: "Skåne", municipalities:
            [
                { value: "Bjuv", label: "Bjuv" },
                { value: "Bromölla", label: "Bromölla" },
                { value: "Burlöv", label: "Burlöv" },
                { value: "Båstad", label: "Båstad" },
                { value: "Eslöv", label: "Eslöv" },
                { value: "Helsingborg", label: "Helsingborg" },
                { value: "Hässleholm", label: "Hässleholm" },
                { value: "Höganäs", label: "Höganäs" },
                { value: "Hörby", label: "Hörby" },
                { value: "Höör", label: "Höör" },
                { value: "Klippan", label: "Klippan" },
                { value: "Kristianstad", label: "Kristianstad" },
                { value: "Kävlinge", label: "Kävlinge" },
                { value: "Landskrona", label: "Landskrona" },
                { value: "Lomma", label: "Lomma" },
                { value: "Lund", label: "Lund" },
                { value: "Malmö", label: "Malmö" },
                { value: "Osby", label: "Osby" },
                { value: "Perstorp", label: "Perstorp" },
                { value: "Simrishamn", label: "Simrishamn" },
                { value: "Sjöbo", label: "Sjöbo" },
                { value: "Skurup", label: "Skurup" },
                { value: "Staffanstorp", label: "Staffanstorp" },
                { value: "Svalöv", label: "Svalöv" },
                { value: "Svedala", label: "Svedala" },
                { value: "Tomelilla", label: "Tomelilla" },
                { value: "Trelleborg", label: "Trelleborg" },
                { value: "Vellinge", label: "Vellinge" },
                { value: "Ystad", label: "Ystad" },
                { value: "Åstorp", label: "Åstorp" },
                { value: "Ängelholm", label: "Ängelholm" },
                { value: "Örkelljunga", label: "Örkelljunga" },
                { value: "Östra Göinge", label: "Östra Göinge" }
            ]

    },
    {
        value: "Östergötland", label: "Östergötland", municipalities:
            [
                { value: "Boxholm", label: "Boxholm" },
                { value: "Finspång", label: "Finspång" },
                { value: "Kinda", label: "Kinda" },
                { value: "Linköping", label: "Linköping" },
                { value: "Mjölby", label: "Mjölby" },
                { value: "Motala", label: "Motala" },
                { value: "Norrköping", label: "Norrköping" },
                { value: "Söderköping", label: "Söderköping" },
                { value: "Vadstena", label: "Vadstena" },
                { value: "Valdemarsvik", label: "Valdemarsvik" },
                { value: "Ydre", label: "Ydre" },
                { value: "Åtvidaberg", label: "Åtvidaberg" },
                { value: "Ödeshög", label: "Ödeshög" }
            ]
    },
    {
        value: "Uppsala", label: "Uppsala", municipalities:
            [
                { value: "Uppsala", label: "Uppsala" },
                { value: "Enköping", label: "Enköping" },
                { value: "Knivsta", label: "Knivsta" },
                { value: "Håbo", label: "Håbo" },
                { value: "Östhammar", label: "Östhammar" },
                { value: "Tierp", label: "Tierp" },
                { value: "Älvkarleby", label: "Älvkarleby" },
                { value: "Heby", label: "Heby" }
            ]
    },
    {
        value: "Jönköping", label: "Jönköping", municipalities:
            [
                { value: "Aneby", label: "Aneby" },
                { value: "Eksjö", label: "Eksjö" },
                { value: "Gislaved", label: "Gislaved" },
                { value: "Gnosjö", label: "Gnosjö" },
                { value: "Habo", label: "Habo" },
                { value: "Jönköping", label: "Jönköping" },
                { value: "Mullsjö", label: "Mullsjö" },
                { value: "Nässjö", label: "Nässjö" },
                { value: "Sävsjö", label: "Sävsjö" },
                { value: "Tranås", label: "Tranås" },
                { value: "Vaggeryd", label: "Vaggeryd" },
                { value: "Vetlanda", label: "Vetlanda" },
                { value: "Värnamo", label: "Värnamo" }
            ]

    },
    {
        value: "Halland", label: "Halland", municipalities:
            [
                { value: "Falkenberg", label: "Falkenberg" },
                { value: "Halmstad", label: "Halmstad" },
                { value: "Hylte", label: "Hylte" },
                { value: "Kungsbacka", label: "Kungsbacka" },
                { value: "Laholm", label: "Laholm" },
                { value: "Varberg", label: "Varberg" }
            ]

    },
    {
        value: "Örebro", label: "Örebro", municipalities:
            [
                { value: "Askersund", label: "Askersund" },
                { value: "Degerfors", label: "Degerfors" },
                { value: "Hallsberg", label: "Hallsberg" },
                { value: "Hällefors", label: "Hällefors" },
                { value: "Karlskoga", label: "Karlskoga" },
                { value: "Kumla", label: "Kumla" },
                { value: "Laxå", label: "Laxå" },
                { value: "Lekeberg", label: "Lekeberg" },
                { value: "Lindesberg", label: "Lindesberg" },
                { value: "Ljusnarsberg", label: "Ljusnarsberg" },
                { value: "Nora", label: "Nora" },
                { value: "Örebro", label: "Örebro" }
            ]
    },
    {
        value: "Södermanland", label: "Södermanland", municipalities:
            [
                { value: "Eskilstuna", label: "Eskilstuna" },
                { value: "Flen", label: "Flen" },
                { value: "Gnesta", label: "Gnesta" },
                { value: "Katrineholm", label: "Katrineholm" },
                { value: "Nyköping", label: "Nyköping" },
                { value: "Oxelösund", label: "Oxelösund" },
                { value: "Strängnäs", label: "Strängnäs" },
                { value: "Trosa", label: "Trosa" },
                { value: "Vingåker", label: "Vingåker" }
            ]

    },
    {
        value: "Dalarna", label: "Dalarna", municipalities:
            [
                { value: "Avesta", label: "Avesta" },
                { value: "Borlänge", label: "Borlänge" },
                { value: "Falun", label: "Falun" },
                { value: "Gagnef", label: "Gagnef" },
                { value: "Hedemora", label: "Hedemora" },
                { value: "Leksand", label: "Leksand" },
                { value: "Ludvika", label: "Ludvika" },
                { value: "Malung", label: "Malung" },
                { value: "Mora", label: "Mora" },
                { value: "Orsa", label: "Orsa" },
                { value: "Rättvik", label: "Rättvik" },
                { value: "Smedjebacken", label: "Smedjebacken" },
                { value: "Säter", label: "Säter" },
                { value: "Vansbro", label: "Vansbro" },
                { value: "Älvdalen", label: "Älvdalen" }
            ]

    },
    {
        value: "Gävleborg", label: "Gävleborg", municipalities:
            [
                { value: "Bollnäs", label: "Bollnäs" },
                { value: "Gävle", label: "Gävle" },
                { value: "Hofors", label: "Hofors" },
                { value: "Hudiksvall", label: "Hudiksvall" },
                { value: "Ljusdal", label: "Ljusdal" },
                { value: "Nordanstig", label: "Nordanstig" },
                { value: "Ockelbo", label: "Ockelbo" },
                { value: "Ovanåker", label: "Ovanåker" },
                { value: "Sandviken", label: "Sandviken" },
                { value: "Söderhamn", label: "Söderhamn" }
            ]

    },
    {
        value: "Värmland", label: "Värmland", municipalities:
            [
                { value: "Arvika", label: "Arvika" },
                { value: "Eda", label: "Eda" },
                { value: "Filipstad", label: "Filipstad" },
                { value: "Forshaga", label: "Forshaga" },
                { value: "Grums", label: "Grums" },
                { value: "Hagfors", label: "Hagfors" },
                { value: "Hammarö", label: "Hammarö" },
                { value: "Karlstad", label: "Karlstad" },
                { value: "Kil", label: "Kil" },
                { value: "Kristinehamn", label: "Kristinehamn" },
                { value: "Munkfors", label: "Munkfors" },
                { value: "Storfors", label: "Storfors" },
                { value: "Sunne", label: "Sunne" },
                { value: "Säffle", label: "Säffle" },
                { value: "Torsby", label: "Torsby" },
                { value: "Årjäng", label: "Årjäng" }
            ]

    },
    {
        value: "Västmanland", label: "Västmanland", municipalities:
            [
                { value: "Arboga", label: "Arboga" },
                { value: "Fagersta", label: "Fagersta" },
                { value: "Hallstahammar", label: "Hallstahammar" },
                { value: "Kungsör", label: "Kungsör" },
                { value: "Köping", label: "Köping" },
                { value: "Norberg", label: "Norberg" },
                { value: "Sala", label: "Sala" },
                { value: "Skinnskatteberg", label: "Skinnskatteberg" },
                { value: "Surahammar", label: "Surahammar" },
                { value: "Västerås", label: "Västerås" }
            ]

    },
    {
        value: "Västerbotten", label: "Västerbotten", municipalities:
            [
                { value: "Bjurholm", label: "Bjurholm" },
                { value: "Dorotea", label: "Dorotea" },
                { value: "Lycksele", label: "Lycksele" },
                { value: "Malå", label: "Malå" },
                { value: "Nordmaling", label: "Nordmaling" },
                { value: "Norsjö", label: "Norsjö" },
                { value: "Robertsfors", label: "Robertsfors" },
                { value: "Skellefteå", label: "Skellefteå" },
                { value: "Sorsele", label: "Sorsele" },
                { value: "Storuman", label: "Storuman" },
                { value: "Umeå", label: "Umeå" },
                { value: "Vilhelmina", label: "Vilhelmina" },
                { value: "Vindeln", label: "Vindeln" },
                { value: "Vännäs", label: "Vännäs" },
                { value: "Åsele", label: "Åsele" }
            ]

    },
    { value: "Norrbotten", label: "Norrbotten", municipalities:
        [
            { value: "Arjeplog", label: "Arjeplog" },
            { value: "Arvidsjaur", label: "Arvidsjaur" },
            { value: "Boden", label: "Boden" },
            { value: "Gällivare", label: "Gällivare" },
            { value: "Haparanda", label: "Haparanda" },
            { value: "Jokkmokk", label: "Jokkmokk" },
            { value: "Kalix", label: "Kalix" },
            { value: "Kiruna", label: "Kiruna" },
            { value: "Luleå", label: "Luleå" },
            { value: "Pajala", label: "Pajala" },
            { value: "Piteå", label: "Piteå" },
            { value: "Älvsbyn", label: "Älvsbyn" },
            { value: "Överkalix", label: "Överkalix" },
            { value: "Övertorneå", label: "Övertorneå" }
          ]
          
    },
    { value: "Kalmar", label: "Kalmar", municipalities: 
        [
            { value: "Borgholm", label: "Borgholm" },
            { value: "Emmaboda", label: "Emmaboda" },
            { value: "Hultsfred", label: "Hultsfred" },
            { value: "Högsby", label: "Högsby" },
            { value: "Kalmar", label: "Kalmar" },
            { value: "Mönsterås", label: "Mönsterås" },
            { value: "Mörbylånga", label: "Mörbylånga" },
            { value: "Nybro", label: "Nybro" },
            { value: "Oskarshamn", label: "Oskarshamn" },
            { value: "Torsås", label: "Torsås" },
            { value: "Vimmerby", label: "Vimmerby" },
            { value: "Västervik", label: "Västervik" }
          ]
          
    },
    { value: "Västernorrland", label: "Västernorrland", municipalities:
        [
            { value: "Härnösand", label: "Härnösand" },
            { value: "Kramfors", label: "Kramfors" },
            { value: "Sollefteå", label: "Sollefteå" },
            { value: "Sundsvall", label: "Sundsvall" },
            { value: "Timrå", label: "Timrå" },
            { value: "Ånge", label: "Ånge" },
            { value: "Örnsköldsvik", label: "Örnsköldsvik" }
          ]
          
    },
    { value: "Kronoberg", label: "Kronoberg", municipalities:
        [
            { value: "Alvesta", label: "Alvesta" },
            { value: "Lessebo", label: "Lessebo" },
            { value: "Ljungby", label: "Ljungby" },
            { value: "Markaryd", label: "Markaryd" },
            { value: "Tingsryd", label: "Tingsryd" },
            { value: "Uppvidinge", label: "Uppvidinge" },
            { value: "Växjö", label: "Växjö" },
            { value: "Älmhult", label: "Älmhult" }
        ]
          
    },
    { value: "Blekinge", label: "Blekinge", municipalities:
        [
            { value: "Karlshamn", label: "Karlshamn" },
            { value: "Karlskrona", label: "Karlskrona" },
            { value: "Olofström", label: "Olofström" },
            { value: "Ronneby", label: "Ronneby" },
            { value: "Sölvesborg", label: "Sölvesborg" }
        ]
          
     },
    { value: "Jämtland", label: "Jämtland", municipalities:
        [
            { value: "Berg", label: "Berg" },
            { value: "Bräcke", label: "Bräcke" },
            { value: "Härjedalen", label: "Härjedalen" },
            { value: "Krokom", label: "Krokom" },
            { value: "Ragunda", label: "Ragunda" },
            { value: "Strömsund", label: "Strömsund" },
            { value: "Åre", label: "Åre" },
            { value: "Östersund", label: "Östersund" }
        ]
          
    },
    { value: "Gotland", label: "Gotland", municipalities: 
        [{value: "Gotland", label: "Gotland"}]
     },
]