import logo from './logo.svg';
import { Component } from 'react';
import Select from 'react-select';
import { regions } from './Regioner';
import localizedStrings, { locales } from './locale';
import getCenters from './data'
import RadarClass from './Radar'
import './App.css';



let langs = [
  { label: "SV", value: "sv" },
  { label: "EN", value: "en" }
]
class App extends Component {
  constructor(props) {
    super(props)
    this.updateRegion = this.updateRegion.bind(this);
    this.updateMuni = this.updateMuni.bind(this);
    this.updateCenters = this.updateCenters.bind(this);
    this.updateLang = this.updateLang.bind(this);
    this.state = {
      lang: "SE",
      currentRegion: "Stockholm",
      selectedMuni: "Stockholm",
      currentMuni: [],
      selectedCenters: [],
      currentCenters: [],
      municsNotExist: true
    };
  }

  updateLang() {
    let newLang = document.getElementById("lang-selector").value;
    localizedStrings.setLanguage(newLang)
    this.setState({
      lang: newLang
    })
  }
  updateRegion(newReg) {

    this.setState({
      currentRegion: newReg.value,
      currentMuni: newReg.municipalities,
      selectedMuni: newReg.municipalities[0],
      municsNotExist: false,
      currentCenters: getCenters(newReg.value, newReg.municipalities[0].value),
      selectedCenters: [],
    })
  }
  updateMuni(newMuni) {
    this.setState({
      selectedCenters: [],
      selectedMuni: newMuni,
      currentCenters: getCenters(this.state.currentRegion, newMuni.value)
    })
  }
  updateCenters(newCenters) {
    if(newCenters.length > 1) {
      document.getElementById("center-selection").classList.add("smallerCenterText")
    } else {
      document.getElementById("center-selection").classList.remove("smallerCenterText")
    }
    this.setState({
      selectedCenters: newCenters
    })
  }

  render() {
    let charts;
    if((this.state.selectedCenters.length > 0)) {
      charts = <RadarClass data={this.state.selectedCenters} />;
      
    } else {
      charts = <p className='colored-text'>Select at least one center to view data.</p>;
    }
    return (
      <div className="App">
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />


          <div className='sub-menu'>
            <Select placeholder={localizedStrings.region+"..."} defaultValue={"Stockholm"} onChange={this.updateRegion} options={regions} className='selection non-center' />
            <Select placeholder={localizedStrings.municipality+".."} value={this.state.selectedMuni} onChange={this.updateMuni} options={this.state.currentMuni} isDisabled={this.state.currentMuni.length < 1} className='selection non-center' />
            <Select placeholder={localizedStrings.caregiver+"..."} value={this.state.selectedCenters} id="center-selection" isMulti={true} hideSelectedOptions={true} options={this.state.currentCenters} onChange={this.updateCenters} isDisabled={this.state.currentMuni.length < 1} className='selection centerSelection' />
          </div>
          <select id="lang-selector" className='lang-select' value={this.state.lang} onChange={this.updateLang}>
            <option value="en">EN</option>
            <option value="sv">SV</option>
            <option value="fi">FI</option>
            <option value="ar">العربية</option>
            <option value="ku">کوردی</option>
            <option value="es">ES</option>
          </select>
          <div class="break"></div>

        </header>

        <div className="body">
          <div className='boxes'>
            <div className='contentBox'>
              <RadarClass data={this.state.selectedCenters} />
            </div>
            <div className='contentBox'>
              <div className="chat-bubble">
                <img src={logo} className="App-logo" alt="logo" />
                <p className='chat-bubble ai-text'>{localizedStrings.ai_tba}</p>
              </div>

            </div>
          </div>
        </div>
        <footer className='footer'>
          {localizedStrings.footer}
        </footer>

      </div>
    )
  };
}

export default App;
